import { FunctionComponent, useEffect, useState } from 'react';
import { AlDate } from '@/lib/date/AlDate';

interface RelativeTimeDisplayProps {
  date: AlDate;
}

const RelativeTimeDisplay: FunctionComponent<RelativeTimeDisplayProps> = ({ date }) => {
  const [_, setNow] = useState(new AlDate());

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new AlDate());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return <span>{date.relativeTimeFromNow()}</span>;
};

export default RelativeTimeDisplay;
