import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignGroupsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useTranslation } from '@/lib/i18n/useTranslate';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import CampaignGroupsActions from '@/modules/optimizer/components/campaign-groups/CampaignGroupsActions';
import CampaignGroupsFilterBar from '@/modules/optimizer/components/campaign-groups/CampaignGroupsFilterBar';
import CampaignGroupsTable from '@/modules/optimizer/components/campaign-groups/CampaignGroupsTable';
import { CampaignGroupsProvider } from '@/modules/optimizer/contexts/CampaignGroupsContext';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import type { GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import ProfileSyncSelectButton from '../../optimizer/ProfileSyncSelectButton';
import UpgradeSubscriptionButton from '../../teams/components/UpgradeSubscriptionButton';
import { campaignService, createCampaignGroupsWithMetricsQueryKey } from '../api/campaign/campaign-service';
import { CampaignGroupModel } from '../api/campaign/models/CampaignGroupModel';
import CampaignGroupsSelectionActionsBar from '../components/campaign-groups/CampaignGroupsSelectionActionsBar';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import { Button } from '@mui/material';
import { OndemandVideo } from '@mui/icons-material';
import { VideoUrl } from '@/config/urls';
import { UserSettingKey } from '@/modules/users';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';

const CampaignGroupsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { fetchCache } = useAlFetchCache();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const [selectedCampaignGroups, setSelectedCampaignGroups] = useState<CampaignGroupModel[]>([]);

  const [filters, setFilters] = useState<AlFilterModel[]>(() =>
    filtersService.loadProfileFilters(ContextKey.CAMPAIGN_GROUPS, activeProfile?.id ?? '', getDefaultCampaignGroupsFilters(), fetchCache),
  );

  // GRID
  const gridApi = useRef<GridApi | null>(null);

  function onGridReady(params: GridReadyEvent) {
    gridApi.current = params.api;
  }

  // MAIN GRID DATA
  const { data: campaignGroupsWithMetricData, isLoading } = useQuery({
    queryKey: createCampaignGroupsWithMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await campaignService.getGroupsWithMetrics(filters);

      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.OPTIMIZATION_GROUPS_TUTORIAL_DISPLAY_COUNT,
  );

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={
          <>
            <div className="flex flex-row items-center gap-4">
              {t('optimization_groups')}
              <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideo />}>
                How to Use Optimization Groups
              </Button>
            </div>
            <VideoModal
              isOpen={isTutorialModalOpen}
              onClose={onTutorialModalClose}
              url={VideoUrl.OPTIMIZATION_GROUPS}
              title={'How to Use Optimization Groups'}
            />
          </>
        }
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <CampaignGroupsProvider
          filters={filters}
          setFilters={setFilters}
          selectedCampaignGroups={selectedCampaignGroups}
          setSelectedCampaignGroups={setSelectedCampaignGroups}
          totalCampaignGroupsCount={campaignGroupsWithMetricData?.campaignGroups?.filter((cg) => cg.id != 0).length ?? 0}
        >
          <ErrorBoundary>
            <CampaignGroupsActions campaignGroupsWithMetrics={campaignGroupsWithMetricData} />
          </ErrorBoundary>

          <ErrorBoundary>
            <CampaignGroupsFilterBar campaignGroupsWithMetrics={campaignGroupsWithMetricData} gridApiRef={gridApi} />
          </ErrorBoundary>

          <ErrorBoundary>
            <CampaignGroupsTable
              withMetricsResponse={campaignGroupsWithMetricData}
              onGridReadyCallback={onGridReady}
              isLoading={isLoading}
              noTopBorderRadius={true}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <CampaignGroupsSelectionActionsBar />
          </ErrorBoundary>
        </CampaignGroupsProvider>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default CampaignGroupsPage;
