import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import SellingPartnerNotConnectedAlert from '@/components/feedback/SellingPartnerNotConnectedAlert';
import ShowXTimesAlert from '@/components/feedback/ShowXTimesAlert';
import { getDefaultAdvertisedProductsFilters } from '@/components/filter-builder/models/AlFilterModel';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { createAdvertisedProductsWithTimelineQueryKey, productsService } from '@/modules/products/api/products-service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridReadyEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import AdvertisedProductsFilterBar from '../components/AdvertisedProductsFilterBar';
import AdvertisedProductsTable from '../components/advertised-products-table/AdvertisedProductsTable';
import { SelectedAdvertisedProduct } from '../models/AdvertisedProductModel';

const AdvertisedProductsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { refetchUser } = useUserContext();

  usePaywallManager({
    route: Routes.ADVERTISED_PRODUCTS,
    message: 'Upgrade to Pro to Access the Advertised Products Tool Suite',
    canUsePage: (team) => team.subscriptionPlan.canUseAdvertisedProductsPage,
  });

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.ADVERTISED_PRODUCTS,
    metricsUserSettingKey: UserSettingKey.VISIBLE_METRICS_ADVERTISED_PRODUCTS,
    defaultFilters: getDefaultAdvertisedProductsFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
  });

  const [selectedAdvertisedProducts, setSelectedAdvertisedProducts] = useState<SelectedAdvertisedProduct[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const advertisedProductsTableGridApiRef = useRef<GridApi | null>(null);
  // function clearSelections() {
  //   setSelectedAdvertisedProducts([]);
  //   if (advertisedProductsTableGridApiRef.current && !advertisedProductsTableGridApiRef.current.isDestroyed()) {
  //     advertisedProductsTableGridApiRef.current.deselectAll();
  //   }
  // }

  const advertisedProductsWithTimelineQueryKey = createAdvertisedProductsWithTimelineQueryKey(activeProfile?.id, pageVariables.filters);
  const {
    data: advertisedProductsWithTimeline,
    isLoading: isAdvertisedProductsRowDataLoading,
    isError: isAdvertisedProductsLoadingError,
    error: advertisedProductsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: advertisedProductsWithTimelineQueryKey,

    queryFn: async () => {
      const result = await productsService.getAdvertisedProductsWithTimeline(pageVariables.filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading advertisedProducts ' + JSON.stringify(result));
      }
    },
    enabled: pageVariables.isFiltersEnabled && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching advertised products', isFetching);

  function onAdvertisedProductsTableGridReady(params: GridReadyEvent) {
    advertisedProductsTableGridApiRef.current = params.api;
  }

  // // TUTORIAL MODAL
  // const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  // const onTutorialModalClose = () => {
  //   setIsTutorialModalOpen(false);
  // };

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex items-center gap-x-2">
                <div>{t('advertised_products')}</div>
                <div>
                  <Tooltip
                    title={
                      'Product advertising data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)'
                    }
                  >
                    <span className="flex items-center font-medium">
                      <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton disableFilters={pageVariables.disableFilters} />{' '}
              </div>
            }
          />
        )}
        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            {!isTableExpanded && (
              <>
                <ShowXTimesAlert
                  timesToDisplay={3}
                  title="Advertised Product Data"
                  content="Advertised Product data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)."
                  userSettingKey={UserSettingKey.ADVERTISED_PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT}
                />

                <SellingPartnerNotConnectedAlert />
              </>
            )}

            {!isTableExpanded && (
              <div className="mt-2">
                <AlErrorBoundary>
                  <MetricsContainer
                    metricValues={advertisedProductsWithTimeline?.metrics ?? []}
                    isLoading={isAdvertisedProductsRowDataLoading}
                    isError={isAdvertisedProductsLoadingError}
                    error={advertisedProductsLoadingError}
                    visibleMetrics={pageVariables.visibleMetrics}
                    setVisibleMetrics={pageVariables.setVisibleMetrics}
                    showComparison={pageVariables.gridToggles.comparisonUnit != 'hidden'}
                  />
                </AlErrorBoundary>
              </div>
            )}
            {!isTableExpanded && (
              <div className="my-2">
                <AlErrorBoundary>
                  <MetricTimelineChart
                    visibleMetrics={pageVariables.visibleMetrics}
                    timelineData={advertisedProductsWithTimeline?.timeline}
                    isLoading={isAdvertisedProductsRowDataLoading}
                    isError={isAdvertisedProductsLoadingError}
                    error={advertisedProductsLoadingError}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <AdvertisedProductsFilterBar
                withTimeline={advertisedProductsWithTimeline}
                gridApiRef={advertisedProductsTableGridApiRef}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
                pageVariables={pageVariables}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <AdvertisedProductsTable
                withTimeline={advertisedProductsWithTimeline}
                isLoading={isAdvertisedProductsRowDataLoading}
                selectedAdvertisedProducts={selectedAdvertisedProducts}
                setSelectedAdvertisedProducts={setSelectedAdvertisedProducts}
                advertisedProductsLoadingErrorMessage={
                  advertisedProductsLoadingError instanceof Error ? advertisedProductsLoadingError.message : ''
                }
                isAdvertisedProductsLoadingError={isAdvertisedProductsLoadingError}
                onGridReadyCallback={onAdvertisedProductsTableGridReady}
                noTopBorderRadius={true}
                isExpanded={isTableExpanded}
                pageVariables={pageVariables}
              />
            </AlErrorBoundary>
          </PageLayoutBody>
        )}
      </PageLayout>
      {/* <AlErrorBoundary>
        <AdvertisedProductsSelectionActionsBar
          selectedAdvertisedProducts={selectedAdvertisedProducts}
          setSelectedAdvertisedProducts={setSelectedAdvertisedProducts}
          totalAdvertisedProductsCount={advertisedProductsWithTimeline?.advertisedProducts ? advertisedProductsWithTimeline.advertisedProducts.length : 0}
          onDiscardClicked={clearSelections}
          advertisedProductsTableGridApiRef={advertisedProductsTableGridApiRef}
        />
      </AlErrorBoundary> */}

      {/* <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={''}
        title={'How to Use the Search Terms Tab'}
      /> */}
    </>
  );
};

export default AdvertisedProductsPage;
