import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';
import DaypartingScheduleListItem from './DaypartingScheduleListItem';
import { DaypartingScheduleModel } from '../types/DaypartingScheduleModel';
import LoadingBlock from '@/components/feedback/LoadingBlock';

interface DaypartingScheduleListProps {
  schedules: DaypartingScheduleModel[];
  isLoading: boolean;
}

const DaypartingScheduleList: FunctionComponent<DaypartingScheduleListProps> = ({ schedules, isLoading }) => {
  if (isLoading) {
    return (
      <div className="flex shrink-0 w-full items-center justify-center">
        <LoadingBlock />
      </div>
    );
  }

  if (!schedules || schedules.length === 0) {
    return <Typography>No schedules available.</Typography>;
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      {schedules.map((schedule, index) => (
        <DaypartingScheduleListItem key={schedule.id} schedule={schedule} />
      ))}
    </div>
  );
};

export default DaypartingScheduleList;
