import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { useTranslation } from '@/lib';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Alert, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridReadyEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { campaignMappingService, createCampaignMappingQueryKey } from '../api/campaign-mapping-service';
import CampaignMappingSelectionActionsBar from '../components/CampaignMappingActionsBar';
import CampaignMappingFilterBar from '../components/CampaignMappingFilterBar';
import CampaignMappingTable from '../components/campaign-mapping-table/CampaignMappingTable';
import { CampaignMappingProvider } from '../contexts/CampaignMappingContext';
import { CampaignMappingModel } from '../models/CampaignMappingModel';

const CampaignMappingPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { fetchCache } = useAlFetchCache();

  const { activeTeam, activeProfile } = useActiveTeamContext();
  usePaywallManager({
    route: Routes.CAMPAIGN_MAPPING,
    message: 'Upgrade to Pro to Access the Campaign Mapping Tool Suite',
    videoUrl: VideoUrl.KW_HARVESTING,
    canUsePage: (team) => team.subscriptionPlan.canUseCampaignMappingPage,
  });

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(
      ContextKey.CAMPAIGN_MAPPING,
      activeProfile?.id ?? '',
      getDefaultCampaignMappingFilters(),
      fetchCache,
    );
  });

  const [selectedCampaignMappings, setSelectedCampaignMappings] = useState<CampaignMappingModel[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const campaignMappingTableGridApiRef = useRef<GridApi<CampaignMappingModel> | null>(null);
  function clearSelections() {
    setSelectedCampaignMappings([]);
    if (campaignMappingTableGridApiRef.current && !campaignMappingTableGridApiRef.current.isDestroyed()) {
      campaignMappingTableGridApiRef.current.deselectAll();
    }
  }

  const {
    data: campaignMappingRowData,
    isLoading: isCampaignMappingRowDataLoading,
    isError: isCampaignMappingRowDataLoadingError,
    error: campaignMappingRowDataLoadingError,
    isFetching,
  } = useQuery({
    queryKey: createCampaignMappingQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await campaignMappingService.getCampaignMappingData(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        // If no mappings found, return empty array
        if (result.httpResponseCode === 404) {
          return [];
        }
        toastService.error('Error loading campaign mappings ' + JSON.stringify(result));
        throw new Error('Error loading campaign mappings ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching campaign mapping', isFetching);

  function onCampaignMappingTableGridReady(params: GridReadyEvent) {
    campaignMappingTableGridApiRef.current = params.api;
  }

  useEffect(() => {
    // Can't do in queryFn because it's not called on invalidation
    // Not doing just refetch because invalidation includes also other keys
    clearSelections();
  }, [campaignMappingRowData]);

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.CAMPAIGN_MAPPING_TUTORIAL_DISPLAY_COUNT,
  );

  // Row count in master table
  const totalMappingsCount = campaignMappingRowData ? campaignMappingRowData.length : 0;
  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={
            <div className="flex flex-row items-center gap-4">
              {t('campaign_mapping')}
              <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                How to Harvest Keywords & Product Targets
              </Button>
            </div>
          }
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <TeamSelect />
              <ProfileSyncSelectButton />
            </div>
          }
        />

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <CampaignMappingProvider filters={filters} setFilters={setFilters}>
              <div className="mt-4"></div>
              {campaignMappingRowData && campaignMappingRowData.length == 0 && filters.length == 0 && (
                <Alert className="mb-4" severity="info">
                  No campaign mappings found. Import or create them manually.
                </Alert>
              )}
              <AlErrorBoundary>
                <CampaignMappingFilterBar rowData={campaignMappingRowData} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <CampaignMappingTable
                  rowData={campaignMappingRowData ?? []}
                  isLoading={isCampaignMappingRowDataLoading}
                  selectedCampaignMappings={selectedCampaignMappings}
                  setSelectedCampaignMappings={setSelectedCampaignMappings}
                  campaignMappingLoadingErrorMessage={
                    campaignMappingRowDataLoadingError instanceof Error ? campaignMappingRowDataLoadingError.message : ''
                  }
                  isCampaignMappingLoadingError={isCampaignMappingRowDataLoadingError}
                  onGridReadyCallback={onCampaignMappingTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>
            </CampaignMappingProvider>
          </PageLayoutBody>
        )}
      </PageLayout>

      <AlErrorBoundary>
        <CampaignMappingSelectionActionsBar
          selectedCampaignMappings={selectedCampaignMappings}
          setSelectedCampaignMappings={setSelectedCampaignMappings}
          totalMappingsCount={totalMappingsCount}
          onDiscardClicked={clearSelections}
          mappingsTableGridApiRef={campaignMappingTableGridApiRef}
          filters={filters}
          rowData={campaignMappingRowData ?? []}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.KW_HARVESTING}
        title={'How to Harvest Keywords & Product Targets'}
      />
    </>
  );
};

export default CampaignMappingPage;
