import React, { FunctionComponent, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { isNil } from 'lodash-es';
import { toastService } from '@/services/toast.service';
import { createCampaignIdsQueryKey, campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { AlMultiSelectOptionModel } from '../../../components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '../../../components/form/AlMultiSelect';
import { filterFactory } from '../../../components/filter-builder/models/FilterFactory';
import { getTodayDateFilters } from '../../../components/filter-builder/models/AlFilterModel';
import useCampaignDaypartingAssignmentWarnings from '@/modules/optimizer/hooks/useCampaignDaypartingAssignmentWarnings';
import { mapCampaignToSelectedCampaignDTO } from '@/modules/optimizer/api/campaign/models/CampaignModel';

interface DaypartingCampaignSelectProps {
  selectedCampaignIds: string[];
  onChange: (newIds: string[]) => void;
  hideIndividualChips?: boolean;
}

const DaypartingCampaignSelect: FunctionComponent<DaypartingCampaignSelectProps> = ({
  selectedCampaignIds,
  onChange,
  hideIndividualChips,
}) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { filterValidItems } = useCampaignDaypartingAssignmentWarnings({ selectedItems: [] });

  const { data: campaigns, isLoading } = useQuery({
    queryKey: ['dayparting_filtered_campaigns_for_selection'],
    queryFn: async () => {
      const result = await campaignService.getCampaignsWithTimeline(getTodayDateFilters());
      if (result.isSuccess) {
        return filterValidItems(result.payload.campaigns.map(mapCampaignToSelectedCampaignDTO));
      } else {
        toastService.error('Error loading campaigns');
        return [];
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const options = useMemo(() => {
    if (!campaigns) return [];
    return campaigns.map((campaign) => new AlMultiSelectOptionModel(campaign.name, campaign.id)).sort((a, b) => a.name.localeCompare(b.name));
  }, [campaigns]);

  return (
    <AlMultiSelect
      options={options}
      selectedOptionIds={selectedCampaignIds}
      setSelectedOptionIds={onChange}
      label="Campaigns"
      placeholderText="Select campaigns"
      hideIndividualChips={hideIndividualChips}
      width="500px"
      isLoading={isLoading}
    />
  );
};

export default DaypartingCampaignSelect;
