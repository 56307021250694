import { QueryClient } from '@tanstack/react-query';

export function invalidateDaypartingQuery(queryClient: QueryClient, activeProfileId: string | undefined) {
  queryClient.invalidateQueries({ queryKey: [activeProfileId, 'dayparting'] });
}

export function invalidateGetAllDaypartingSchedulesQuery(queryClient: QueryClient, activeProfileId: string | undefined) {
  if (!activeProfileId) return;
  queryClient.invalidateQueries({ queryKey: createGetAllDaypartingSchedulesQueryKey(activeProfileId) });
}

export function createGetAllDaypartingSchedulesQueryKey(activeProfileId: string | undefined) {
  return [activeProfileId, 'dayparting', 'getAllSchedules'];
}
