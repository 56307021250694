import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import { VideoUrl } from '@/config/urls';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { OpenInNew } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Alert, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { daypartingScheduleService } from '../api/dayparting.service';
import DaypartingScheduleList from '../components/DaypartingScheduleList';
import { DaypartingScheduleModel } from '../types/DaypartingScheduleModel';
import { createGetAllDaypartingSchedulesQueryKey } from '../api/dayparting.cache';

const DaypartingOverviewPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const { data, isFetching, refetch } = useQuery({
    queryKey: createGetAllDaypartingSchedulesQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await daypartingScheduleService.getAll();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading Dayparting Schedules');
      }
    },
  });

  usePaywallManager({
    route: Routes.DAYPARTING,
    message: 'Upgrade to Pro to Access the Dayparting Schedules',
    canUsePage: (team) => team.subscriptionPlan.canUseDayparting,
  });

  async function onCreateClicked() {
    const daypartingSchedule = DaypartingScheduleModel.generateDefaultDaypartingSchedule();

    const daysToSubmit = DaypartingScheduleModel.convertDaysFromPercentageChanges(daypartingSchedule);

    const response = await daypartingScheduleService.create({
      days: daysToSubmit,
      name: `New Schedule ${data?.length ? data.length + 1 : 1}`,
    });

    if (response.isSuccess) {
      refetch();
      toastService.success('Dayparting Schedule created successfully');
    } else {
      toastService.error('Error creating Dayparting Schedule');
    }
  }

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(UserSettingKey.DAYPARTING_TUTORIAL_DISPLAY_COUNT);

  function onNavigateToDaypartingCalculatorClicked() {
    window.open('https://www.google.com', '_blank'); // DPTODO: For Romet to fill in the correct link
  }

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={
          <div className="flex flex-row items-center gap-4">
            {t('dayparting_schedule')}
            <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
              How to Use Dayparting
            </Button>
          </div>
        }
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      />

      <PageLayoutBody>
        <div className="mt-2 flex flex-1 flex-grow flex-col max-w-[1410px] pb-20">
          <div className="flex mb-2 ">
            <Alert className="py-2 flex-1 " severity="info">
              Daypartying schedules run in the <span className="font-bold">profile's timezone</span>. When campaigns are being dayparted,
              AdLabs will override bid changes made outside of AdLabs.
            </Alert>
          </div>
          <div className="mb-2 flex flex-row items-center">
            <div className="flex items-center">
              <Button onClick={onCreateClicked} startIcon={<AddIcon />}>
                Create New Schedule
              </Button>
              <Button
                className="ml-4"
                variant="outlined"
                endIcon={<OpenInNew fontSize="inherit" />}
                onClick={onNavigateToDaypartingCalculatorClicked}
              >
                AdLabs Dayparting Template
              </Button>
            </div>

            <VideoModal
              isOpen={isTutorialModalOpen}
              onClose={onTutorialModalClose}
              url={VideoUrl.DAYPARTING}
              title={'How to Use Dayparting'}
            />
            <div className="flex-grow"></div>
          </div>

          <AlErrorBoundary>
            <div className="flex w-full">
              <DaypartingScheduleList schedules={data ?? []} isLoading={isFetching} />
            </div>
          </AlErrorBoundary>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default DaypartingOverviewPage;
