import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { isNumber } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import { daypartingScheduleService } from '../../api/dayparting.service';
import { DaypartingScheduleFormModel } from './DaypartingScheduleFormModel';
import { DaypartingScheduleState } from '../../api/dayparting.contracts';
import DaypartingScheduleFormLogic from './DaypartingScheduleFormLogic';
import { DaypartingScheduleModel } from '../../types/DaypartingScheduleModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { invalidateProfile_campaignsWithTimelineQueryKeys } from '@/modules/optimizer/api/campaign/campaign-service';
import { invalidateGetAllDaypartingSchedulesQuery } from '../../api/dayparting.cache';

interface DaypartingScheduleFormProps {
  schedule?: DaypartingScheduleModel; // if provided, we're in edit mode
}

const DaypartingScheduleForm: FunctionComponent<DaypartingScheduleFormProps> = ({ schedule }) => {
  const isCreate = !schedule;
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();

  const handleSubmit = async (data: DaypartingScheduleFormModel) => {
    if (isCreate) {
      const response = await daypartingScheduleService.create({
        days: data.days,
        name: data.name,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.NAME_ALREADY_EXISTS) {
          toast('A Dayparting Schedule with this name already exists', { type: 'error' });
        } else {
          toast('Error saving Dayparting Schedule', { type: 'error' });
        }
      } else {
        invalidateProfile_campaignsWithTimelineQueryKeys(queryClient, activeProfile?.id);
      }
    } else if (schedule && isNumber(schedule.id)) {
      const response = await daypartingScheduleService.update(schedule.id, {
        days: data.days,
        name: data.name,
        schedule_id: schedule.id,
        state: data.state,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.NAME_ALREADY_EXISTS) {
          toast('A Dayparting Schedule with this name already exists', { type: 'error' });
        } else {
          toast('Error saving Dayparting Schedule', { type: 'error' });
        }
      } else {
        // Save the campaigns as well
        const assignResponse = await daypartingScheduleService.assign({
          assignments: [{ schedule_id: schedule.id, campaign_ids: data.campaignIds }],
        });
        if (!assignResponse.isSuccess) {
          toast('Error saving Dayparting Schedule', {
            type: 'error',
          });
        }

        invalidateProfile_campaignsWithTimelineQueryKeys(queryClient, activeProfile?.id);
      }
    }
    return true;
  };

  const initialValues: DaypartingScheduleFormModel = isCreate
    ? {
        name: 'New Schedule',
        state: DaypartingScheduleState.Enabled,
        days: DaypartingScheduleModel.generateDefaultDaypartingSchedule(),
        campaignIds: [],
      }
    : {
        name: schedule!.name,
        state: schedule!.state,
        days: schedule!.daysWithPercentageChanges,
        campaignIds: schedule!.campaignIds,
      };

  return (
    <DaypartingScheduleFormLogic
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isCreate={isCreate}
      daypartingScheduleId={schedule ? schedule.id : 0}
    />
  );
};

export default DaypartingScheduleForm;
