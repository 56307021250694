import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import SellingPartnerNotConnectedAlert from '@/components/feedback/SellingPartnerNotConnectedAlert';
import ShowXTimesAlert from '@/components/feedback/ShowXTimesAlert';
import { getDefaultProductsFilters } from '@/components/filter-builder/models/AlFilterModel';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import { SellerMetricField, VendorMetricField } from '@/components/metrics/types/MetricField';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { CurrentAndMaxCount } from '@/modules/search-query-performance/api/search-query-performance-contracts';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_AVAILABLE_METRICS, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridReadyEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { createProductsWithTimelineQueryKey, productsService } from '../api/products-service';
import ProductsFilterBar from '../components/ProductsFilterBar';
import ProductsSelectionActionsBar from '../components/ProductsSelectionActionsBar';
import ProductsTable from '../components/products-table/ProductsTable';
import { SelectedProduct } from '../models/ProductModel';

const ProductsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  usePaywallManager({
    route: Routes.PRODUCTS,
    message: 'Upgrade to Pro to Access the Products Tool Suite',
    canUsePage: (team) => team.subscriptionPlan.canUseProductsPage,
  });

  function getMetricsUserSettingKey() {
    if (activeProfile?.isSeller) {
      return UserSettingKey.VISIBLE_METRICS_PRODUCTS_SELLER;
    }

    if (activeProfile?.isVendor) {
      return UserSettingKey.VISIBLE_METRICS_PRODUCTS_VENDOR;
    }

    return UserSettingKey.VISIBLE_METRICS_PRODUCTS;
  }

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.PRODUCTS,
    metricsUserSettingKey: getMetricsUserSettingKey(),
    defaultFilters: getDefaultProductsFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
    availableMetrics: [
      ...DEFAULT_AVAILABLE_METRICS,
      ...(activeProfile?.isSeller ? Object.values(SellerMetricField) : []),
      ...(activeProfile?.isVendor ? Object.values(VendorMetricField) : []),
    ],
  });

  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const productsTableGridApiRef = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedProducts([]);
    if (productsTableGridApiRef.current && !productsTableGridApiRef.current.isDestroyed()) {
      productsTableGridApiRef.current.deselectAll();
    }
  }

  const productsWithTimelineQueryKey = createProductsWithTimelineQueryKey(activeProfile?.id, pageVariables.filters);
  const {
    data: productsWithTimeline,
    isLoading: isProductsRowDataLoading,
    isError: isProductsLoadingError,
    error: productsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: productsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await productsService.getProductsWithTimeline(pageVariables.filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading products ' + JSON.stringify(result));
      }
    },
    enabled: pageVariables.isFiltersEnabled && !isEmpty(activeProfile?.id),
  });

  const sqpCurrentAndMaxCount: CurrentAndMaxCount = {
    currentCount: productsWithTimeline?.currentSQPAsinAssignCount ?? 0,
    maxCount: productsWithTimeline?.maxSQPAsinAssignCount ?? 0,
  };

  useGlobalLoadingStateObserver('isFetching products', isFetching);

  function onProductsTableGridReady(params: GridReadyEvent) {
    productsTableGridApiRef.current = params.api;
  }

  // // TUTORIAL MODAL
  // const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
  //   UserSettingKey.PRODUCTS_TUTORIAL_DISPLAY_COUNT,
  // );
  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex items-center gap-x-2">
                <div>{t('products')}</div>
                <div>
                  <Tooltip
                    title={
                      'Product advertising data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)'
                    }
                  >
                    <span className="flex items-center font-medium">
                      <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton disableFilters={pageVariables.disableFilters} />
              </div>
            }
          />
        )}

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            {!isTableExpanded && (
              <>
                <ShowXTimesAlert
                  timesToDisplay={3}
                  title="Product Data"
                  content="Product advertising data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)"
                  userSettingKey={UserSettingKey.PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT}
                />

                <SellingPartnerNotConnectedAlert />
              </>
            )}

            {!isTableExpanded && (
              <div className="mt-2">
                <AlErrorBoundary>
                  <MetricsContainer
                    metricValues={productsWithTimeline?.metrics}
                    isLoading={isProductsRowDataLoading}
                    isError={isProductsLoadingError}
                    error={productsLoadingError}
                    visibleMetrics={pageVariables.visibleMetrics}
                    setVisibleMetrics={pageVariables.setVisibleMetrics}
                    availableMetrics={pageVariables.availableMetrics}
                    showComparison={pageVariables.gridToggles.comparisonUnit != 'hidden'}
                  />
                </AlErrorBoundary>
              </div>
            )}

            {!isTableExpanded && (
              <div className="my-2">
                <AlErrorBoundary>
                  <MetricTimelineChart
                    visibleMetrics={pageVariables.visibleMetrics}
                    timelineData={productsWithTimeline?.timeline}
                    isLoading={isProductsRowDataLoading}
                    error={productsLoadingError}
                    isError={isProductsLoadingError}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <ProductsFilterBar
                withTimeline={productsWithTimeline}
                pageVariables={pageVariables}
                gridApiRef={productsTableGridApiRef}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <ProductsTable
                withTimeline={productsWithTimeline}
                pageVariables={pageVariables}
                isLoading={isProductsRowDataLoading}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                productsLoadingErrorMessage={productsLoadingError instanceof Error ? productsLoadingError.message : ''}
                isProductsLoadingError={isProductsLoadingError}
                onGridReadyCallback={onProductsTableGridReady}
                isExpanded={isTableExpanded}
                noTopBorderRadius={true}
                filters={pageVariables.filters}
              />
            </AlErrorBoundary>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <ProductsSelectionActionsBar
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          totalProductsCount={productsWithTimeline?.products ? productsWithTimeline.products.length : 0}
          onDiscardClicked={clearSelections}
          productsTableGridApiRef={productsTableGridApiRef}
          sqpCurrentAndMaxCount={sqpCurrentAndMaxCount}
        />
      </AlErrorBoundary>

      {/* <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={''}
        title={'How to Use the Search Terms Tab'}
      /> */}
    </>
  );
};

export default ProductsPage;
