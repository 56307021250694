import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import { Environment } from '@/config/Environment';
import { VideoUrl } from '@/config/urls';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { UserSettingKey } from '@/modules/users';
import AddIcon from '@mui/icons-material/Add';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button, Popover } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { CampaignGroupsWithMetrics } from '../../api/campaign/campaign-contracts';
import { CreateUpdateCampaignGroup } from './campaign-groups-popover/CreateUpdateCampaignGroup';
import CampaignGroupsBulkImportButton from './CampaignGroupsBulkImportButton';

interface CampaignGroupsActionsProps {
  campaignGroupsWithMetrics: CampaignGroupsWithMetrics | undefined;
}

const CampaignGroupsActions: FunctionComponent<CampaignGroupsActionsProps> = ({ campaignGroupsWithMetrics }) => {
  const rowData = campaignGroupsWithMetrics?.campaignGroups ?? [];

  // CREATE GROUP
  const createButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false); // state to control the popover
  const onCreateClicked = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  const handleCreatePopoverClose = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  return (
    <>
      <div className="mb-4 mt-5 flex flex-row gap-x-2">
        <Button ref={createButtonRef} onClick={onCreateClicked} variant="contained" startIcon={<AddIcon />}>
          Create New Opt Group
        </Button>

        {Environment.isDev() && <CampaignGroupsBulkImportButton />}

        <div className="flex-grow">{/* Filler div */}</div>
      </div>

      <Popover
        id={'create-group-popover'}
        open={isCreatePopoverOpen}
        anchorEl={createButtonRef.current}
        onClose={handleCreatePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { style: { maxWidth: 500 } } }}
      >
        <CreateUpdateCampaignGroup existingGroups={rowData} onClose={handleCreatePopoverClose} flowType={FlowType.OPTIMIZATION_GROUPS} />
      </Popover>
    </>
  );
};

export default CampaignGroupsActions;
