import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { DaypartingScheduleCreateFormSchema, DaypartingScheduleFormModel, DaypartingScheduleFormSchema } from './DaypartingScheduleFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import DaypartingScheduleFormDisplay from './DaypartingScheduleFormDisplay';
import { DaypartingScheduleDayModel, DaypartingScheduleModel } from '../../types/DaypartingScheduleModel';
import { cloneDeep } from 'lodash-es';

interface DaypartingScheduleFormLogicProps {
  initialValues: DaypartingScheduleFormModel;
  onSubmit: (data: DaypartingScheduleFormModel) => Promise<boolean>;
  isCreate?: boolean;
  daypartingScheduleId?: number;
}

const DaypartingScheduleFormLogic: FunctionComponent<DaypartingScheduleFormLogicProps> = ({
  initialValues,
  onSubmit,
  isCreate,
  daypartingScheduleId,
}) => {
  const form = useForm<DaypartingScheduleFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? DaypartingScheduleCreateFormSchema : DaypartingScheduleFormSchema),
  });

  const handleSubmit = async (data: DaypartingScheduleFormModel): Promise<boolean> => {
    // We need to clone the data, so the UI doesn't update before the API call is done
    const dataToSubmit = cloneDeep(data);
    dataToSubmit.days = DaypartingScheduleModel.convertDaysFromPercentageChanges(
      dataToSubmit.days.map((day) => new DaypartingScheduleDayModel(day)),
    );

    try {
      const success = await onSubmit(dataToSubmit);
      if (success) {
        form.reset(data);
      }
      return success;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return (
    <>
      <DaypartingScheduleFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} daypartingScheduleId={daypartingScheduleId} />
    </>
  );
};

export default DaypartingScheduleFormLogic;
