import { FunctionComponent } from 'react';
import { DaypartingScheduleModel } from '../types/DaypartingScheduleModel';
import DaypartingScheduleForm from './dayparting-schedule-form/DaypartingScheduleForm';

interface DaypartingScheduleListItemProps {
  schedule: DaypartingScheduleModel;
}

const DaypartingScheduleListItem: FunctionComponent<DaypartingScheduleListItemProps> = ({ schedule }) => {
  return <DaypartingScheduleForm schedule={schedule} />;
};

export default DaypartingScheduleListItem;
